import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import { Grid, Box, useMediaQuery } from '@material-ui/core'
import Layout from '../components/Layout'
import styled from 'styled-components'
import OptImages from '../components/OptImages'
import ContactSection from '../components/ContactSection'
import PrivacyPolicyPageQuery from '../queries/PrivacyPolicyPageQuery'

const StyledBox = styled(Box)`
  ::-webkit-scrollbar {
    display: none;
  }

  h4 {
    margin: 40px 0 12px;
  }
`
const PrivacyImg = styled(OptImages.PrivacyPolicy)`
  width: 100%;
  height: 50vh;
  z-index: -1;
  ${({ theme }) => theme.breakpoints.up('md')} {
    height: 100vh;
    width: 50vw;
    position: fixed !important;
  }
`

export default () => {
  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'))
  return (
    <PrivacyPolicyPageQuery
      render={(content) => (
        <Layout>
          <Box>
            <Grid
              container
              spacing={0}
              justify='center'
              direction='row-reverse'
            >
              <Grid item xs={12} sm={6} container alignItems='center'>
                <Grid item>
                  <StyledBox
                    color='text.primary'
                    m={smUp ? 10 : 3}
                    my={0}
                    py={smUp ? 7 : 12}
                  >
                    <div
                      style={{
                        height: '110px',
                        width: '110px',
                        background: 'white',
                        borderRadius: '100px',
                        display: 'flex',
                        justifyContent: 'left',
                        alignItems: 'left',
                        marginBottom: '10px'
                      }}
                    >
                      <OptImages.EmojiPrivacy style={{ width: '90px' }} />
                    </div>
                    <h2 style={{ marginTop: '30px' }}>Privacy Policy</h2>
                    <h4>GENERAL INFORMATION</h4>
                    <p>
                      This document describes how Brandie Technologies AB, company registration number
                      559058-6250 having its registered address at Skeppsbron 8, 111 30 Stockholm (”<b>Brandie</b>”
                      “<b>we</b>” or “<b>us</b>”) collects, uses and processes personal data (“<b>Privacy Policy</b>”). This Privacy Policy
                      is applicable when you register and use Brandie’s application, an interactive marketing
                      platform which enables individuals to become brand ambassadors (“<b>Service</b>”).
                      <br />
                      <br />
                      All definitions in this Privacy Policy shall be interpreted in accordance with applicable data
                      protection laws which refers to the General Data Protection Regulation (Regulation no.
                      2016/679) (“<b>GDPR</b>”) and the Directive on Privacy and Electronic Communications (Directive
                      2002/58/EC), as well as the national implementations and related national legislation.
                    </p>
                    <h4>DATA PROCESSED</h4>
                    <p>
                      We will process the following personal data when you register and use the Service:
                      <br />
                      <ol>
                        <li>Name;</li>
                        <li>email address;</li>
                        <li>telephone number;</li>
                        <li>address;</li>
                        <li>pictures of the data subject;</li>
                        <li>information about your use of the Services;</li>
                        <li>content that you post, upload and/or contribute to the Services;</li>
                        <li>technical data, which may include the URL you are accessing the Website from, your
                          IP address, unique device ID, network and computer performance, browser type,
                          language and identifying information and operating system; and
                        </li>
                        <li>location data.</li>
                      </ol>
                      <br />
                      We are unable to provide you with the Service unless you provide us with the personal data
                      set out above as the functionality of the Service is dependent on such personal data.
                    </p>

                    <h4>PURPOSES OF PROCESSING</h4>
                    <p>
                      We will process the personal data set out above for the following purposes:
                      <br />
                      <ol>
                        <li>To administer your account, to enable and provide the Service and integration with
                          third party services, and to provide, personalize and improve your experience with the
                          Service, and to otherwise provide the Service according to the terms of service;
                        </li>
                        <li>
                          to send you alerts or messages by email or otherwise, including to provide you with
                          marketing of our and our related parties’ products and services;
                        </li>
                        <li>
                          to inform you about updates of the Service or the terms of service;
                        </li>
                        <li>
                          to improve and develop the Service or new services and products and to analyse your
                          use of the Service;
                        </li>
                        <li>
                          to ensure the technical functioning of the Service and to prevent use of the Service in
                          breach of the terms of service;
                        </li>
                        <li>
                          to enforce the terms of service, including to protect our rights, property and safety and
                          also the rights, property and safety of third parties if necessary; and
                        </li>
                        <li>
                          to fulfil requirements in accordance with applicable law.
                        </li>
                      </ol>
                    </p>

                    <h4>LEGAL GROUND</h4>
                    <p>
                      By opting in upon registering to our Service you consent to the processing of your personal
                      data as set out in this Privacy Policy.
                    </p>

                    <h4>DISCLOSURE OF PERSONAL DATA</h4>
                    <p>
                      We will share and disclose your personal data with brand owners located in the EU/EEA
                      connected to the Service.
                      <br />
                      <br />
                      When using the Service you may be directed to other websites where the personal data
                      collected is not in our control. The privacy policy of the other website will govern the personal
                      data obtained from you on that website.
                    </p>

                    <h4>RESPONDING TO LEGAL REQUESTS AND PREVENTING HARM</h4>
                    <p>
                      We may access, preserve and share your personal data in response to a legal request (e.g. a
                      search warrant, court order or a subpoena or the like), or when necessary to detect, prevent
                      and address fraud and other illegal activity, to protect ourselves, you and other users,
                      including as part of investigations.
                    </p>

                    <h4>COOKIES, PIXELS AND OTHER SIMILAR TECHNOLOGIES</h4>
                    <p>
                      Cookies, pixels and/or other similar technologies are small pieces of data sent from e.g. a
                      website and stored in a user's computer, mobile phone, or other IT device while the user is
                      browsing that website. There are different kinds of cookies, pixels and/or other similar
                      technologies. For example, “persistent cookies” are saved as a file during a longer period,
                      while “session cookies” are temporarily placed on the computer, mobile phone or other IT
                      device when a user is visiting a website. Session cookies disappear when the web browser is
                      shut down.
                      <br />
                      <br />
                      We use cookies, pixels and/or information collected by default provided by Firebase{' '}
                      <a
                        href='https://firebase.google.com'
                        target='_blank'
                        rel='noreferrer'
                      >
                        (https://firebase.google.com/)
                      </a>{' '}
                      to collect and analyze data, inter alia, in order to receive information
                      regarding user patterns in the Service, which helps us improve our Service and ensures that
                      it meets the needs of our users.
                      <br />
                      <br />
                      We will not disclose any personal data about our use of cookies, pixels and/or other similar
                      technologies to third parties. The only information that may be disclosed is information that
                      cannot be linked to individual users (e.g. information relating to the number of users in
                      connection with each campaign). Cookies, pixels and/or other similar technologies are stored
                      a few days up to over a year, depending on how often you visit the Service.
                    </p>

                    <h4>RETENTION</h4>
                    <p>
                      We will process your personal data as long as necessary in order to provide you with the
                      Service and/or in order to fulfil requirements in accordance with applicable law. Personal
                      data is deleted/anonymized when the data no longer needs to be kept (e.g. when you stop
                      using the Service and delete your account and/or when the retention time set out in
                      applicable law, if any, is due). Deleted or anonymized data cannot be recovered. To request
                      retention or deletion of personal data please refer to the segment “contact information”
                      below.
                    </p>

                    <h4>CHILDREN</h4>
                    <p>
                      The Services are not directed to persons under the age of 13 years. If you are a parent or
                      guardian of a person under the age of 13 and you become aware of that the child has
                      provided personal data to us without your consent, please contact{' '}
                      <a
                        href='mailto:info@brandie.in?subject=Data Request'
                        target='_blank'
                        rel='noreferrer'
                      >info@brandie.in
                      </a>
                      to exercise your access, rectification, erasure, limiting of processing and objection rights.
                    </p>

                    <h4>YOUR RIGHTS</h4>
                    <p>
                      You have an absolute right to object to the processing of your personal data for direct
                      marketing. You also have the right to withdraw your prior given consent. The withdrawal of
                      your consent does not affect the lawfulness of the processing based on the consent before
                      its withdrawal, and we may continue processing your personal data based on other legal
                      grounds, except for direct marketing.
                      <br />
                      <br />
                      You have the right to request access and further information concerning the processing of
                      your personal data, or request that we correct, rectify, complete, erase or restrict the
                      processing of your personal data. You have the right to obtain a copy of the personal data
                      that we process relating to you free of charge once (1) every calendar year. For any additional
                      copies requested by you, we may charge a reasonable fee based on administrative costs.
                      <br />
                      <br />
                      If the processing is based on the legal grounds consent or fulfilment of contract you have the
                      right to data portability. Data portability means that you can receive the personal data that
                      you have provided to us, in a structured, commonly used and machine-readable format, and
                      have the right to transfer such data to another data controllers.
                    </p>

                    <h4>CONTACT INFORMATION</h4>
                    <p>
                      To exercise your rights, or if you have any questions regarding our processing of your
                      personal data, please contact us at the following address:{' '}
                      <a
                        href='mailto:info@brandie.in?subject=Data Request'
                        target='_blank'
                        rel='noreferrer'
                      >info@brandie.in.
                      </a> {' '}
                      In your letter/email please state the username and e-mail you used upon registering for our Service.
                      Note that you should sign the request to receive information of the processing of your
                      personal data yourself.
                      <br />
                      <br />
                      If you have any complaints regarding our processing of your personal data, you may file a
                      complaint to the competent data protection authority. You can find out more about the local
                      data protection authorities under the following link:{' '}
                      <a
                        href='http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm'
                        target='_blank'
                        rel='noreferrer'
                      >
                        http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
                      </a>
                    </p>

                    <h4>CHANGE OF CONTROL</h4>
                    <p>
                      If there is a change of control in our business, we may transfer your personal data to the new
                      owners for them to continue to provide the Service. The new owners shall continue to comply
                      with the commitments we have made in this Privacy Policy.
                    </p>

                    <h4>NOTICE OF CHANGES TO THE PRIVACY POLICY</h4>
                    <p>
                      If we make changes to this Privacy Policy, we will notify you by e-mail, notifications in the
                      Service and/or push notifications. If your consent is required due to the changes, we will
                      provide you additional prominent notice as appropriate under the circumstances and ask for
                      your consent in accordance with applicable law.
                    </p>
                  </StyledBox>
                </Grid>
              </Grid>
              <Grid item xs={12} md={6}>
                <PrivacyImg />
              </Grid>
            </Grid>
          </Box>

          <ContactSection />
        </Layout>
      )}
    />
  )
}
